export const geoJson = {
	"type": "FeatureCollection",
	"query": [
		-77.29084672026431,
		38.84415730085976
	],
	"features": [
		{
			"id": "address.6366048655318594",
			"type": "Feature",
			"place_type": [
				"address"
			],
			"relevance": 1,
			"properties": {
				"accuracy": "point",
                "title": "Curry Masala",
                "icon": "monument"
			},
			"text": "Main Street",
			"place_name": "9980 Main Street, Fairfax, Virginia 22031, United States",
			"center": [
				-77.2908335,
				38.8442823
			],
			"geometry": {
				"type": "Point",
				"coordinates": [
					-77.2908335,
					38.8442823
				]
			},
			"address": "9980",
			"context": [
				{
					"id": "neighborhood.270878",
					"text": "Fair Oaks"
				},
				{
					"id": "postcode.15705231953112580",
					"text": "22031"
				},
				{
					"id": "place.14283028869013360",
					"wikidata": "Q501785",
					"text": "Fairfax"
				},
				{
					"id": "region.16625906770758790",
					"short_code": "US-VA",
					"wikidata": "Q1370",
					"text": "Virginia"
				},
				{
					"id": "country.9053006287256050",
					"short_code": "us",
					"wikidata": "Q30",
					"text": "United States"
				}
			]
		}
	],
	"attribution": "NOTICE: © 2019 Mapbox and its suppliers. All rights reserved. Use of this data is subject to the Mapbox Terms of Service (https://www.mapbox.com/about/maps/). This response and the information it contains may not be retained. POI(s) provided by Foursquare."
}
