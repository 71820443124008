import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import { PopupOptions } from 'mapbox-gl';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import { geoJson } from './map_geo_json';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

export interface MapProps {

}

const styles = {
  map: {
    position: 'static' as 'static',
    top: '0px',
    bottom: '0px',
    width: '100%',
    transition: 'all 0.3s'
  }
};

type propsWithStyle = MapProps & WithStyles<typeof styles>;

class MapBox extends Component<propsWithStyle> {

  public container?: HTMLElement;

  componentDidMount() {
    const center: [number, number] = [geoJson.features[0].center[0], geoJson.features[0].center[1]];
    const map = new mapboxgl.Map({
      container: this.container!,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center,
      zoom: 15.0,
    })
    map.scrollZoom.disable();

    map.on('load', function() {
      map.addLayer({
        'id': 'points',
        'type': 'symbol',
        // data from opendata.cityofboise.org/
        'source': {
          'type': 'geojson',
          'data': {
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    -77.29084672026431,
                    38.84415730085976
                  ]
                },
                "properties": {
                  "title": "Curry Mantra - Indian Restaurant",
                  "icon": "marker"
                }
              }
            ]
          }

        },
        "layout": {
          "icon-image": "{icon}-15",
          "text-field": "{title}",
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 0.6],
          "text-anchor": "top"
        }
      });

    });

    var markerHeight = 50, markerRadius = 10, linearOffset = 25;

    const offsetProps = {
       'top': [20, 20] as [number, number],
       'top-left': [0, 0] as [number, number],
       'top-right': [0,0] as [number, number],
       'bottom': [0, -markerHeight] as [number, number],
       'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1] as [number, number],
       'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1] as [number, number],
       'left': [markerRadius, (markerHeight - markerRadius) * -1] as [number, number],
       'right': [-markerRadius, (markerHeight - markerRadius) * -1] as [number, number]
    };
    const o = {
      offset: offsetProps,
      closeOnClick: true
    };
    const popup = new mapboxgl.Popup(o as PopupOptions)
      .setLngLat(center)
      .setHTML('<h3><a href="https://goo.gl/maps/MLik5XZ5DmdkS62g9">Curry Mantra - Indian Restaurant</a></h3><p>9984 Main St, Fairfax, VA 22031</p>');

    popup.addTo(map);
  }

  render() {
    return (
      <div ref={el => {
        if (el !== undefined) {
          this.container = el as HTMLElement;
        }
      }} className={this.props.classes.map} />
    );
  }
}

export default withStyles(styles)(MapBox);
