import React, { PureComponent } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import logo from './images/baby.svg';

const styles = {
  grow: {
    flexGrow: 1,
  },
  leftBox: {
    height: '64px',
    display: 'flex',
    alignItems: 'center', // Vertical alignment of logo and breadcrumbs
  },
  breadcrumbs: {
    fontSize: '18px',
  },
  logo: {
    padding: '0px',
    marginRight: '16px',
  },
};

type PropsWithStyles = WithStyles<typeof styles>;

class Navigation extends PureComponent<PropsWithStyles> {
  render() {
    const { classes } = this.props;
    return (
      <AppBar
        position="fixed"
        elevation={0}
        color="primary"
      >
        <Toolbar>
          <div className={classes.leftBox}>
            <Button
              disableRipple
              classes={{ root: classes.logo }}
            >
              <a href="/"><img src={logo} alt="Baby Logo" width="90" height="32" /></a>
            </Button>
          </div>
          <div className={classes.leftBox}>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Stella's Baby Shower 2019
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Navigation);
