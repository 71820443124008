import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { styles } from './Home';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

class WhiteNoise extends PureComponent<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props;    
    return (
      <Grid item xs={12}>
        <div className={classes.container}>
          <Paper className={classes.section1} elevation={1}>            
            <AudioPlayer
              autoPlay={false}
              loop
              src="https://static.tuladhar.dev/white_noise_long.mp3"
              
            />
          </Paper>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(WhiteNoise);
