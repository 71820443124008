import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import MapBox from './MapBox';

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    map: {

    },
    section1: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      //margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
    section2: {
      margin: theme.spacing.unit * 2,
    },
    section3: {
      margin: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
  });

class Home extends PureComponent<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <div className={classes.container}>
          <Paper className={classes.section1} elevation={1}>
            <Typography variant="h5" component="h4">
              You are invited to Stella's Baby Shower on May 26, 2019 12 PM - 4 PM
            </Typography>

          </Paper>

          <Paper className={classes.section1} elevation={1}>
            <Typography color="textSecondary">
              Baby Registry @ <a href="https://bit.ly/30apgrR" target="_blank" rel="noopener noreferrer">BuyBuyBaby</a> and <a href="https://amzn.to/2vLLlyL" target="_blank" rel="noopener noreferrer">Amazon.com</a>
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
                Please RSVP by May 19, 2019.
                <Divider variant="middle" />
                Prajwal's number: 646.355.8250
            </Typography>
          </Paper>

          <Paper>
            <MapBox />
          </Paper>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(Home);
