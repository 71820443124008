import React, { Component } from 'react';
import './App.css';

import Grid from '@material-ui/core/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import withRoot from './withRoot';
import Navigation from './Navigation';

import variables from './styles/variables';

import Home from './pages/Home';
import WhiteNoise from './pages/WhiteNoise';
import NotFound from './NotFound';

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: variables.white,
    height: '100%',
    minHeight: '600px',
    width: '100%',
    overflowX: 'unset' as 'unset',
    marginLeft: '10px',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  headerOffset: {
    marginTop: '64px',
  },
};

class App extends Component<WithStyles<typeof styles>> {
  render() {
    return (
      <div className={this.props.classes.headerOffset}>
        <Router>
          <Navigation />
          <div className={this.props.classes.root}>
            <Grid container justify="flex-start" spacing={0}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/noise" component={WhiteNoise} />
                <Route component={NotFound} />
              </Switch>
            </Grid>
          </div>
        </Router>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(App));
