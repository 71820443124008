import React, { Component } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const styles = {
  notFound: {
    margin: '150px auto',
    textAlign: 'center' as 'center',
    width: '80%',
  }
};

class NotFound extends Component<WithStyles<typeof styles> & RouteComponentProps<{}>> {
  render() {
    return (
      <div className={this.props.classes.notFound}>
        <h1>The link &quot;{ this.props.location.pathname }&quot; was not found!</h1>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(NotFound));
